import * as React from "react"
import Layout from "../components/Layout"
import Content from "../components/Content"
import { graphql } from "gatsby"

// markup
const IndexPage = ({ data }) => {
  return (
    <Layout title="Legal">
      <Content data={data} />
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulSeite(slug: { eq: "legal" }) {
      content {
        __typename
        ... on ContentfulContentIntro {
          headline
          smallHeadline
          subline {
            raw
          }
          Image {
            file {
              url
            }
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, PNG]
            )
          }
        }
        ... on ContentfulContentTextBild {
          headline
          isTextSmall
          textTwoColumns
          text {
            raw
          }
          imagePosition
          image {
            file {
              url
            }
            title
            gatsbyImageData(width: 1000, placeholder: BLURRED, formats: [AUTO])
          }
        }
        ... on ContentfulContentProjektListe {
          projects {
            title
            slug
            shortDescription
            mainImage {
              file {
                url
              }
              title
              gatsbyImageData(
                width: 800
                height: 560
                placeholder: BLURRED
                formats: [AUTO]
              )
            }
            tag: tags {
              title
            }
          }
        }
      }
    }
  }
`

export default IndexPage
